import { graphql, navigate, PageProps } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import Hero from "../components/Hero"
import Carousel from "../components/LogoCarousel"
import { selectionsContext } from "../components/Layout"
import CareerMobile from "../components/ProgressTab/CareerMobile"
import { State } from "../global"
import Primary from "../components/ProgressTab/Primary"
import { useSteps } from "../hooks/useSteps"
import Industry from "../components/Cards/Industry"
import copy from "../data/career.yml"
import tw from "twin.macro"
import Seo from "../components/SEO"
import useAuth from "../hooks/useAuth"
import { authCheck, isBrowser } from "../helpers"
import ProgressTab from "../components/ProgressTab"


const CareerPage: React.FC<{ data: any, pageContext:any }> = ({ data, pageContext }) => {
  ;<pre>{JSON.stringify(data, null, 4)}</pre>

  const { dispatch }: any = useContext(selectionsContext)
  const careerId = data.allOption1.nodes.map((option: any )=> (option.option1Value)).indexOf(pageContext.option1selection)
  const progress = useSteps("1")
  const [sponsors, setSponsors] = useState(null)  
  const {isAuthenticated, logout} = useAuth()
  authCheck()
  
  useEffect(() => {
        fetch(`${process.env.GATSBY_API_ROOT_URL}/event-configs/${process.env.GATSBY_EVENT_CONFIG_ID}/sponsors?Option1=${careerId+1}`)
        .then(response => response.json())
        .then(data => setSponsors(data))
    
    }, [])

    useEffect(()=>{
      dispatch({ type: "UPDATE_INDUSTRY", industry: "" })
      dispatch({ type: "UPDATE_ROLE", role: "" })

  }, [])

  return (
    <>
      <Seo title="Choose Industry" />
      <Primary data={progress} kiosk={0} active={false}  />
      <Hero />
      <Industry
        data={data.allOption1.nodes}
        messages={data.allOption1ScreenMessage.nodes}
        pageContext= {pageContext}
        careerId={careerId}
      />
       <div css={[tw`lg:pt-28`]}>
        <Carousel data={sponsors} header={copy.carousel_header}/>
      </div>
      <ProgressTab.Mobile data={progress} kiosk={0}/>
    </>
  )
}

export const query = graphql`
  {
    allOption1 {
      nodes {
        option1Value
        children {
          ... on Option2 {
            option2Value
            children {
              ... on Option3 {
                option3Value
              }
            }
          }
        }
      }
    }
    allOption1ScreenMessage {
      nodes {
        id
        screenMessage
        options {
          screenMessage
          icon
          label
          options {
            screenMessage
            icon
            label
          }
        }
      }
    }
    allCardsYaml {
      nodes {
        card
        headline
        subheading
        items {
          label
          url
        }
      }
    }
    allSponsor {
    nodes {
      id
      logo
      name
      sponsorID
      email
      booth
    }
  }
  }
`

export default CareerPage
